// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Vendor node_modules 
var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');

// Vendor node_modules 
require('@fancyapps/fancybox');
require('slick-carousel');
require('flickity');
require('material-scrolltop');

// Scripts
require('./modules/scripts');













